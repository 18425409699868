import React from "react"
import { graphql, Link } from "gatsby"
import Layout from "../components/layout"

export default ({ data }) => {
  return (
    <Layout>
      <div>
        <h1>Ministries</h1>
        <ul>
          {data.allMarkdownRemark.edges.map(({ node }, index) => (
                <li key={index}>
                  <Link to={node.fields.slug}>{node.frontmatter.title}</Link>
                </li>
          ))}
        </ul>
      </div>
    </Layout>
  )
}

export const query = graphql`
  {
    allMarkdownRemark(sort: {fields: frontmatter___title, order: ASC}, filter: {fields: {collection: {eq: "ministries"}}}) {
      edges {
        node {
          id
          fields {
            slug
          }
          html
          frontmatter {
            director
            title
          }
        }
      }
      totalCount
    }
  }
`
